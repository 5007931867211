.container {
    background-color: #011222;
    padding: 5vw;
    // height: 100vh;
}

.title {
    color: white;
    font-size: 4vw;
    text-align: center;
    text-decoration: underline;
}

.content {
    padding: 0 10vw;
    // display: flex;
    // flex-direction: row;
    margin-top: 2vw;
    text-align: justify;
    overflow: hidden;
    color: white;
}

// .textPhoto {
//     margin-left: 2vw;
//     color: white;
//     font-size: 14px;
// }

// .text {
//     font-size: 14px;
//     color: white;
// }

.image {
    // height: 15vw;
    // width: 12vw;
    border-radius: 28px;
    float: left; // Или `right`, если хотите, чтобы текст обтекал картинку справа
    margin-right: 20px; // Добавляет пространство между картинкой и текстом
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .content {
        padding: 0;
    }
    
    .title {
        font-size: 4vh;
    }
}
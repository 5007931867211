form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #011222;
  
    .selectField {
      height: 40px; // Можно настроить под свои размеры
      border-radius: 4px; // Мягкие углы
      border: 1px solid #ccc; // Серая граница
  
      &:focus {
        border-color: blue; // Граница при фокусе
        outline: none; // Убрать стандартный outline
      }
    }
  
    label {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    button {
      background-color: #011222;
      color:#ccc;
      padding: 1vw;
    }
  }
  
// Modal.module.scss
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; // убедитесь, что это значение выше, чем у других элементов
  }
  
  .modalContent {
    background: #dbefb3;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    // max-width: 500px;
    width: fit-content;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
.container {
    // background-image: url('../../fig/4-1.jpg');
    // background-size: cover; /* Это растянет изображение, чтобы оно покрыло весь элемент */
    // background-position: center; /* Центрирование изображения */
    // background-repeat: no-repeat; /* Избегаем повторения изображения */
    height: 100vh;
    width: 100%;
    padding: 3%;
    overflow: hidden;
    position: relative;
}

.videoback {
  position: absolute; /* Абсолютное позиционирование относительно контейнера */
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%; /* Ширина и высота равны 100%, чтобы покрыть весь контейнер */
  height: 100%;
  object-fit: cover; /* Сохраняем аспектное соотношение, но заполняем контейнер полностью */
  z-index: -1; /* Видео будет на заднем фоне, не перекрывая содержимое контейнера */
}


.bgImage {
  position: absolute; /* Измените позиционирование на абсолютное */
  top: 0;
  left: 0;
  height: 100%; /* Это заполнит весь .container */
  width: 100%; /* Это заполнит весь .container */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1; 
}


.navigation {
    height: 15vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 5%;
    font-size: 2vw;
    font-weight: bold;
    // text-shadow: 2px 2px 4px #000000;
}

.link {
    color: white;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    text-shadow: 2px 2px 4px #000000;
    text-decoration: none;
}

.link:hover {
    color: #ffffff; /* Цвет текста при наведении */
    text-shadow: 0px 0px 10px #86899a; /* Тень текста при наведении */
}

.menu {
    list-style: none;
    display: flex;
    gap: 2rem;
}

.logo {
    width: 30vw;
    align-self: center;
}

.banner {
    height: 85vh;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    // justify-content: end;
    justify-content: space-between;
    align-items: end;
    padding: 5vw;
    gap: 1vw;
}

.bannerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vw;
    // justify-content: end;
    text-decoration: underline;
    font-size: 3.5vh;
}

.logoMono {
    width: 30vw;
    justify-self: flex-end;
    img {
        width: 30vw;
    }
}

.menuButton {
    display: none; /* Скрываем кнопку на больших экранах */
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px #000000;
    cursor: pointer;
  }
  
  @media (max-width: 768px) { /* Адаптивные стили для мобильных устройств */
    .menu {
      display: none; /* По умолчанию скрыто на мобильных */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #BBCF94; /* Полупрозрачный фон */
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: transform 0.3s ease-out;
      transform: translateY(-100%); /* Скрыто сверху */
    }
  
    .menuOpen {
      display: flex; /* Показать меню, когда оно активно */
      transform: translateY(0); /* Нормальное положение */
    }
  
    .menuButton {
        display: block; /* Показываем кнопку на мобильных */
    }

    .closeButton {
        color:#011222;
        font-size: 5vh;
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
    }

    .link {
        font-size: 5vh;
    }
  
    .logo {
      width: 80vw; /* Меньший лого на мобильных устройствах */
    }

    // .bannerb, .bannerh, .banners, .bannert {
    //     position: static; /* или 'relative', если нужно */
    //     /* Сбрасываем стили для абсолютного позиционирования */
    //     top: auto;
    //     right: auto;
    //     bottom: auto;
    //     left: auto;
    //     transform: none;
    //     font-size: 5vh;
    //   }
      
      .banner {
        padding: 15vh 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1vh;
      }

      .bannerText {
        font-size: 4vw;
        text-align: center;
        align-items: center;
      }

      .logoMono {
        width: 50vw;
        img {
            width: 50vw;
        }
      }
  }

.container {
    background-color: #011222;
    padding: 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 220vw;
}
  .row {
    display: flex;
    width: 100%;
    font-size: 2vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 30vw; // Установить фиксированную высоту
    margin-bottom: 3vh;

    &.reverse {
      flex-direction: row-reverse;
    }

    .text {
        color:white;
        padding: 5vw;
        h6 {
          font-size: 2vw;
        }
    }

    video {
      width: 40vw;
      height: 30vw; // Задайте подходящий размер
      display: flex; // Задайте подходящий размер шрифта // Задайте цвет номеров
    }
  }

  .video {
    width: 40vw;
    height: 30vw; // Задайте подходящий размер
    display: flex; // Задайте подходящий размер шрифта // Задайте цвет номеров
    // margin-right: 10px; // Отступ между номером и текстом
    float: right; // Или `right`, если хотите, чтобы текст обтекал картинку справа
    margin-right: 20px; // Добавляет пространство между картинкой и текстом
    margin-bottom: 10px;
}

.text1 {
  // margin-top: 20vw;
  color:white;
  padding: 5vw;
  font-size: 1.5vw;
  h6 {
    font-size: 2vw;
  }
}

.paw {
  display: flex;
  flex-direction: row;
}

  @media (max-width: 768px) {
    .container {
      min-height: 450vh;
    }

    .row {
        display: flex;
        font-size: 2vh;
        flex-direction: column;
        min-height: 80vh; // Установить фиксированную высоту
        margin-bottom: 0;
        justify-content: center;

        &.reverse {
            flex-direction: column;
        }

        .text {
            color:white;
            padding: 1vh;
            font-size: 2vh;
            h6 {
              font-size: 2vh;
            }
        }

        video {
          height: 50vh;
          width: 95%;
          margin-bottom: 0;
          margin-right: 0;
        }
    }
    .video {
        height: 50vh;
        width: 95%;
        margin-bottom: 0;
        margin-right: 0;
    }

    .text1 {
      padding: 0;
      min-height: 70vh;
      font-size: 2vh;
      h6 {
        font-size: 2vh;
      }
      // margin-top: 20vh;
    }

    .paw {
      img {
        margin-bottom: 2vh;;
        align-self: end;
        width: 10vh;
        height: 10vh;
      }
    }
  }
  
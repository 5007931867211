.messengerButtons {
    position: fixed;
    right: 10px; // Расстояние от правого края экрана
    top: 50%; // Центрирование по вертикали
    transform: translateY(-50%); // Дополнительное центрирование по вертикали
    display: flex;
    flex-direction: column;
    gap: 10px; // Расстояние между кнопками
  
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        transform: scale(1.1); // Увеличение размера при наведении
      }
    }
  }
  
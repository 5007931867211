
.container {
    background-color: #011222;
    padding: 5vw;
}
  
  .title {
    font-size: 5vw;
    color: white;
    text-align: right;
    text-decoration: underline;
  }
  
  .advantages {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    // align-items: flex-start;
    padding: 1vh 1vh;
    margin-top: 5vh;
    gap: 2vh;
    justify-content: space-around;
  }
  
  .row {
    display: flex;
    width: 20vw;
    font-size: 1.5vw;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // height: 20vh; // Установить фиксированную высоту
    margin-bottom: 1vh;

    // &.reverse {
    //   flex-direction: row-reverse;
    // }

    .text {
        color:white;
    }
  }

  .numberCircle {
    width: 10vh; // Задайте подходящий размер
    height: 10vh; // Задайте подходящий размер
    border: 4px solid blue; // Задайте цвет рамки круга
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-weight: bold;
    font-size: 4vh; // Задайте подходящий размер шрифта
    color: white; // Задайте цвет номеров
    // margin-right: 10px; // Отступ между номером и текстом
  }

  @media (max-width: 768px) {
    .title {
        font-size: 4vh;
        text-align: center;
    }

    .advantages {
        padding: 0 5vh;
        margin-top: 5vh;
      }
    .row {
        width: 100%;
        display: flex;
        font-size: 2vh;
        flex-direction: column;
        height: 13vh; // Установить фиксированную высоту
        
        &.reverse {
            flex-direction: column;
        }

        .text {
            color:white;
        }
    }
    .numberCircle {
        width: 10vh; // Задайте подходящий размер
        height: 10vh; // Задайте подходящий размер
        border: 4px solid blue; // Задайте цвет рамки круга
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 4vh; // Задайте подходящий размер шрифта
        color: white; // Задайте цвет номеров
    }
  }
  
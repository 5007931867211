.container {
    display: flex;
    flex-direction: column;
    background-color: #BBCF94;
    padding: 1vw;
    gap: 1vw;
    font-size: 2vw;
}

.text {
    font-size: 2vw;
    padding: 1vw;
}

.price {
    padding: 2vw;
}

.gallery {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // width: 100vw; // Ширина вьюпорта
    min-height: 50vh; // Высота вьюпорта
    // max-width: 100%; // Чтобы избежать горизонтального скролла всей страницы
    overflow: hidden; // Скрыть скролл
    position: relative;
  
    @media (max-width: 768px) {
      height: 50vh;
    }
  }

  .title {
    color:#011222;
    font-size: 5vw;
    text-align: left;
    margin-top: 1vh;;
    margin-left: 5%;
    text-decoration: underline;
  }
  
  .selectedImage {
    display: flex;
    margin: auto;
  }
  
  .selectedImage img {
    // width: 100%; // Занимает максимум доступной ширины
    height: 60vh; // Занимает не более 80% от высоты вьюпорта
    object-fit: contain; // Сохраняет пропорции изображения
    margin-bottom: 1vw; // Отступ снизу
    @media (max-width: 768px) {
      // width: 100%;
      height: 30vh;
    }
  }
  
  .activeThumbnail {
    opacity: 1;
    border: 2px solid #011222;
  }
  
  .thumbnailsWrapper {
    position: relative;
    height: 7vw; // Высота контейнера для миниатюр
    @media (max-width: 768px) {
      height: 10vh;
    }
  }
  
  .thumbnails {
    display: flex;
    overflow-x: auto; // Горизонтальный скролл для прокрутки миниатюр
    scroll-behavior: smooth; // Плавная прокрутка
    -webkit-overflow-scrolling: touch; // Для плавного скроллинга на мобильных устройствах
    align-items: center; // Центрирование миниатюр по вертикали
    gap: 1vw; // Расстояние между миниатюрами
    margin-bottom: 1vw;
    margin-left: 3vw;
    margin-right: 3vw;
    @media (max-width: 768px) {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  .thumbnails img {
    // width: auto; // Автоматическая ширина
    height: 6vw; // Высота каждой миниатюры
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
    &:hover,
    &.active {
      opacity: 1; // Полная непрозрачность для активного изображения и при наведении
      border: 2px solid #011222; // Синяя рамка для активного изображения
    }
    @media (max-width: 768px) {
      height: 10vh;
    }
  }
  
  .thumbnailsButton {
    position: absolute;
    font-size: 5vw;
    top: 50%;
    transform: translateY(-50%, -50%);
    z-index: 100;
    background-color: #011222d8;
    color: white;
    border: none;
    padding: 0;
    width: 5vw; // Задаем ширину
    height: 6vw; // Высота равна ширине для создания круга
    border-radius: 50%; // Скругление до полного круга
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
    &.left {
      left: 3vw;
    }
    &.right {
      right: 3vw;
    }
  
    @media (max-width: 768px) {
        font-size: 3vh;
        width: 4vh; // Задаем ширину
        height: 6vh;
    }
  }

  .table {
    width: 95%;
    // max-width: 1000px; // или нужная вам ширина
    margin: 20px auto;
    text-align: center;
    color: white;
  
    h2 {
      margin-bottom: 20px;
    }
  
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      background-color: #0048D1;
  
      thead {
        background-color: #002C7D; // темно-синий фон для шапки таблицы
        color: white;
      }
  
      th, td {
        border: 1px solid #dee2e6; // серая граница
        padding: 0.75rem;
        text-align: left;
  
        &:not(:last-child) {
          border-right: none; // убираем правую границу для всех, кроме последней ячейки
        }
      }
  
      th {
        border-bottom-width: 2px;
      }
  
      tbody tr:nth-of-type(odd) {
        background-color: #003EB0; // зебра-стайл для строк
      }
    }
  }
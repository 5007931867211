@keyframes slideUpAndFade {
    from {
      transform: translateY(100%); /* Начинаем с перемещения модального окна на 100% его высоты вниз */
      opacity: 0; /* Начальная прозрачность */
    }
    to {
      transform: translateY(0);
      opacity: 1; /* Конечная прозрачность */
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.modalOverlay {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050; // Убедитесь, что это значение выше, чем у любых других элементов на странице
    animation: fadeIn 0.8s ease-out;
}
  
  .modalContent {
    background-color: #dbefb3;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    z-index: 1100; // Это значение должно быть выше, чем у .modalOverlay
    animation: slideUpAndFade 1s ease-out;
}
  
  .closeButton {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #011222;
}
  
.form {
    margin-top: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    font-size: 3vw;
    color: #011222;
    margin-bottom: 5px;
}

.input {
    font-size: 3vw;
    // width: 100%;
    // height: 40px;
    margin-bottom: 20px;
    padding: 0 10px;
    border: 1px solid #BBCF94;  
}

.button {
    font-size: 3vw;
    width: 100%;
    // height: 40px;
    padding: 5px 5px;
    background-color: #BBCF94;
    color: #011222;
    border: 1px solid #011222;
}
// ServiceCard.module.scss
.container {
  background-color: #BBCF94;
  padding: 2vw;
}

.services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; // Распределение с пространством по бокам
    gap: 2vw; // Расстояние между карточками
    padding: 2vw; // Отступ внутри контейнера услуг
  }

  .title {
    font-size: 4vw;
    text-decoration: underline;
    color: #011222;
  }
  
  .card {
    background: #BBCF94; // Белый фон карточки
    border-radius: 10px; // Скругление углов карточки
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); // Тень карточки
    overflow: hidden; // Обрезка изображения по углам карточки
    // width: 30vh; // Ширина карточки
    text-align: center; // Текст по центру
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05); // Увеличиваем карточку при наведении
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // Усиление тени при наведении
  }
  
  .image {
    width: 100%; // Ширина изображения
    height: auto; // Высота изображения
    object-fit: cover; // Заполнение блока изображением
  }
  
  .name {
    padding: 1vw; // Отступ внутри названия услуги
    font-size: 2vw; // Размер шрифта
    font-weight: bold; // Жирность шрифта
    color: #011222;
  }

  a{
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .title {
        font-size: 4vh;
        text-align: center;
    }
    .name {
      font-size: 2vh;
    }
  }
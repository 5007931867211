.container {
    background-color: #BBCF94;
    padding: 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3vw;
}

.title {
    color: #011222;
    font-size: 4vw;
    text-align: center;
    text-decoration: underline;
}

.callBack {
    background-color: #011222;
    color: white;
    font-size: 2vw;
    padding: 1vw;
}

.logos {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 2vw;
}

.monobank {
    width: 20vw;
}

.welmex {
    width: 20vw;
}